import React, { FC } from "react"
import { StickyContainer } from "react-sticky"
import { Header } from "./Header"
import { Footer } from "./Footer"
import { Stack } from "../common"

const Layout: FC = ({ children }) => {
  return (
    <StickyContainer>
      <Header />
      <Stack style={{ paddingTop: "var(--s1)" }} space="var(--s3)">
        {children}
        <Footer />
      </Stack>
    </StickyContainer>
  )
}

export { Layout }
