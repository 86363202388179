import { css } from "@emotion/core"
import React from "react"

function PrintrestIcon() {
  return (
    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg"   
    css={css`
    &:hover {
      circle {
        fill: white;
      }
      path {
        fill: #f46a02;
      }
    }
  `}>
    <circle
     cx="19"
     cy="19"
     r="16"
     fill="white"
     id="circle2" />
   <path
     d="m 19.406897,0.31034483 c -10.6040004,0 -19.20000045,8.59599997 -19.20000045,19.20000017 0,10.604 8.59600005,19.2 19.20000045,19.2 10.604,0 19.2,-8.596 19.2,-19.2 0,-10.6040002 -8.596,-19.20000017 -19.2,-19.20000017 z m 1.314,23.75000017 c -1.232,-0.094 -1.748,-0.704 -2.712,-1.288 -0.53,2.782 -1.178,5.45 -3.098,6.844 -0.594,-4.208 0.868,-7.364 1.548,-10.718 -1.158,-1.95 0.138,-5.872 2.582,-4.908 3.006,1.192 -2.604,7.25 1.162,8.008 3.932,0.788 5.538,-6.824 3.1,-9.296 -3.524,-3.5740002 -10.254,-0.082 -9.426,5.034 0.2,1.25 1.494,1.63 0.516,3.356 -2.254,-0.5 -2.928,-2.278 -2.84,-4.648 0.138,-3.88 3.486,-6.5980002 6.842,-6.9720002 4.246,-0.472 8.23,1.5580002 8.782,5.5540002 0.618,4.508 -1.918,9.386 -6.456,9.034 z"
     fill="#8291bd"
     id="path4" />
   </svg>
    
  )
}

export { PrintrestIcon }
