import { css } from "@emotion/core"
import React from "react"

function YoutubeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
      css={css`
        &:hover {
          circle {
            fill: white;
          }
          path {
            fill: #f46a02;
          }
        }
      `}
    >
      <circle cx="20" cy="20" r="20" fill="#8291BD"></circle>
      <path
        fill="#fff"
        d="M17.75 23.02L23.5 19.75L17.75 16.48V23.02Z"
      ></path>
     <path d="M30.5401 14.42C30.4213 13.9454 30.1794 13.5106 29.8387 13.1594C29.4981 12.8082 29.0708 12.5532 28.6001 12.42C26.8801 12 20.0001 12 20.0001 12C20.0001 12 13.1201 12 11.4001 12.46C10.9293 12.5932 10.5021 12.8482 10.1614 13.1994C9.8208 13.5506 9.57887 13.9854 9.46008 14.46C9.1453 16.2056 8.99132 17.9763 9.00008 19.75C8.98886 21.537 9.14285 23.3213 9.46008 25.08C9.59104 25.5398 9.83839 25.9581 10.1782 26.2945C10.5181 26.6308 10.9389 26.8738 11.4001 27C13.1201 27.46 20.0001 27.46 20.0001 27.46C20.0001 27.46 26.8801 27.46 28.6001 27C29.0708 26.8668 29.4981 26.6118 29.8387 26.2606C30.1794 25.9094 30.4213 25.4746 30.5401 25C30.8524 23.2676 31.0064 21.5103 31.0001 19.75C31.0113 17.963 30.8573 16.1787 30.5401 14.42V14.42Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export { YoutubeIcon }
